@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.utility-nav {
	background-color: $brand-legacy-purple;

	a {
		color: $ch-corporate-white;
		font-size: 10px;
		font-weight: 600;
	}

	.icon-link {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;

		// Fallback for older browsers
		& > * {
			margin-right: 5px;
		}
		& > *:last-child {
			margin-right: 0;
		}

		span {
			&:hover {
				text-decoration: none;
				color: $ch-corporate-white;
			}
		}
	}

	nav {
		max-width: $breakpoint-large;
		width: 96%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;

		.links {
			display: flex;
			align-items: center;

			// Fallback for older browsers
			& > * {
				margin-right: 10px;
			}
			& > *:last-child {
				margin-right: 0;
			}

			a:not(.mychart-tooltip a) {
				padding: 10px 0;
				display: flex;
				align-items: center;
			}
		}
	}

	.nav-link-container {
		position: relative;
		display: inline-block;
	}

	.mychart-link {
		position: relative;
		gap: 0;

		&:hover .mychart-tooltip {
			display: block;
			opacity: 1;
			color: $brand-text-color;
		}
	}

	.mychart-tooltip {
		display: none;
		background-color: $brand-gray-very-light;
		color: $brand-text-color;
		width: 200px;
		font-size: 12px;
		padding: 9px;
		position: absolute;
		z-index: 1;
		top: 33px;
		right: 0px;
		text-align: left;
		border: 1px solid #dddddd;
		border-top: none;
		opacity: 0;
		-webkit-transition: opacity 0.2s;
		transition: opacity 0.2s;

		a {
			color: $ch-secondary-purple;
			display: inline;
		}
	}

	@include ch-break-down("sm") {
		display: none;
	}

	.mobile-utility-phone {
		@media (max-width: 880px) {
			max-width: 110px;
			text-wrap: wrap;
		}
	}
}
